<template>
  <section>
    <vue-headful title="FLPN Aviasolutions - List Routes"></vue-headful>
    <contenedor>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <span>
            <i class="fas fa-list"></i>
            &nbsp;
            Routes
          </span>
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div class="columns">
            <div class="column is-4">
              <label class="label">Departure</label>
              <AirportSelect v-model="filter.departure_icao" ref="departure"></AirportSelect>
            </div>
            <div class="column is-4">
              <label class="label">Destination</label>
              <AirportSelect v-model="filter.destination_icao" ref="destination"></AirportSelect>
            </div>
            <div class="column is-2">
              <PaginationResults v-model="pagination.perPage" />
            </div>
            <div class="column">
              <label class="label">&nbsp;</label>
              <button type="button" class="button is-link is-fullwidth" @click="onFilter()">
                <span class="icon">
                  <i class="fas fa-filter"></i>
                </span>
                <span class="has-text-weight-bold is-uppercase">Filter Routes</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-table
      paginated
      backend-pagination
      backend-sorting
      :data="pagination.records"
      :loading="pagination.loading"
      :total="pagination.total"
      :per-page="pagination.perPage"
      :default-sort-direction="pagination.defaultSortOrder"
      :default-sort="[pagination.sortField, pagination.sortOrder]"
      hoverable
      @page-change="onPageChange"
      @sort="onSort"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="Id" sortable>
          {{ props.row.id }}
          <b-tooltip v-if="!props.row.is_viable" label="Non Viable" type="is-dark">
            <b-icon
              v-if="!props.row.is_viable"
              pack="fas"
              icon="exclamation-triangle"
              type="is-danger">
            </b-icon>&nbsp;
          </b-tooltip>
        </b-table-column>
        <b-table-column
          field="is_public"
          label="Public"
          sortable
        >{{ props.row.is_public ? 'YES' : 'NOT' }}</b-table-column>
        <b-table-column field="departure" label="Departure" sortable>{{props.row.departure_icao}}</b-table-column>
        <b-table-column field="sid" label="Sid" sortable>{{ props.row.sid_name}}</b-table-column>
        <b-table-column
          field="sid_transition"
          label="Sid Transition"
          sortable
        >{{ props.row.sid_transition_name }}</b-table-column>
        <b-table-column field="star" label="Star" sortable>{{ props.row.star_name }}</b-table-column>
        <b-table-column
          field="star_transition"
          label="Star Transition"
          sortable
        >{{ props.row.star_transition_name }}</b-table-column>
        <b-table-column field="destination" label="Destination" sortable>{{ props.row.destination_icao }}</b-table-column>
        <b-table-column field="client" label="Client">
          <span v-if="props.row.client">{{ props.row.client.name }}</span>
          <span v-else>-</span>
        </b-table-column>
        <b-table-column field="created_at" label="Created" sortable>{{ props.row.created_at }}</b-table-column>
        <b-table-column field="creator" label="Created by">
          <span v-if="props.row.creator">{{ props.row.creator.name }}</span>
          <span v-else>-</span>
        </b-table-column>
        <b-table-column width="120" label="Actions">
          <div class="field has-addons">
            <p class="control">
              <b-tooltip label="View" type="is-dark">
                <router-link :to="`/routes/${props.row.id}`" class="button is-small is-success">
                  <b-icon pack="far" icon="eye"></b-icon>
                </router-link>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip label="Edit" type="is-dark">
                <router-link
                  :to="`/routes/${props.row.id}/edit`"
                  class="button is-small is-primary"
                >
                  <b-icon pack="far" icon="edit"></b-icon>
                </router-link>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip label="Delete" type="is-dark">
                <button
                  type="button"
                  class="button is-danger is-small"
                  v-on:click="onDeleteRoute(props.row)"
                >
                  <i class="fas fa-minus-circle"></i>
                </button>
              </b-tooltip>
            </p>
          </div>
        </b-table-column>
      </template>
    </b-table>
    </contenedor>
  </section>
</template>
<script>
import RoutesService from "../../services/routes.service.js";
import AirportSelect from "../AirportSelect.vue";
import RoutesPaginationFilter from "../../filters/routes-pagination.filter";
import Contenedor from "@/components/Contenedor";
import PaginationResults from "@/components/shared/PaginationResults";

export default {
  name: "RoutesIndex",
  components: {PaginationResults, Contenedor, AirportSelect },
  data() {
    return {
      isLoading: false,
      routesService: new RoutesService(),
      pagination: {
        records: [],
        total: 0,
        loading: false,
        sortField: "id",
        sortOrder: "desc",
        defaultSortOrder: "asc",
        page: 1,
        perPage: 20
      },
      filter: new RoutesPaginationFilter()
    };
  },
  methods: {
    onReset() {
      this.filter.reset();
      this.paginate();
    },
    onFilter() {
      this.paginate();
    },
    paginate() {
      const params = {
        sort_field: this.pagination.sortField,
        sort_order: this.pagination.sortOrder,
        per_page: this.pagination.perPage,
        page: this.pagination.page
      };
      if (!this.filter.isEmpty()) {
        params.filter = this.generateFilters();
      }
      this.pagination.loading = true;
      this.routesService.paginate(params).then(
        ({ data }) => {
          // api.themoviedb.org manage max 1000 pages
          this.pagination.records = data.data;          
          this.pagination.total = data.meta.total;          
          this.pagination.loading = false;
        },
        () => {
          this.pagination.records = [];
          this.pagination.total = 0;
          this.pagination.loading = false;
          this.$buefy.toast.open({
            message: "Ha ocurrido un error al paginar!!!",
            type: "is-danger"
          });
        }
      );
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.pagination.page = page;
      this.paginate();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.pagination.sortField = field;
      this.pagination.sortOrder = order;
      this.paginate();
    },
    onDeleteRoute(route) {
      this.$buefy.dialog.confirm({
        title: "Deleting Route",
        message: `This will delete the route: </br><b>Departure: ${route.departure}</br>Sid: ${route.sid}</br>Sid Transition: ${route.sidTransition}</br>Star: ${route.star}</br>Star Transition: ${route.starTransition}</br>Destination: ${route.departure}</b></br>from the database, are you sure?`,
        cancelText: "Cancel",
        confirmText: "Yes, delete",
        type: "is-danger",
        size: "is-medium",
        // width: 600,
        hasIcon: true,
        onConfirm: () => {
          const loadingComponent = this.$buefy.loading.open({container: null})
          this.routesService.delete(route.id).then(
            ({ data }) => {
              loadingComponent.close();
              this.paginate();
              this.$buefy.toast.open({
                message: data.notificacion,
                type: "is-success"
              });
            },
            () => {
              loadingComponent.close();
              this.paginate();
              this.$buefy.toast.open({
                message: "An error ocurred while deleting the route.",
                type: "is-danger"
              });
            }
          );
        }
      });
    },
    generateFilters() {
      var filtro = {};
      if (this.filter.hasValue(this.filter.departure_icao)) {
        filtro.departure_icao = this.filter.departure_icao;
      }
      if (this.filter.hasValue(this.filter.destination_icao)) {
        filtro.destination_icao = this.filter.destination_icao;
      }
      return filtro;
    }
  },
  mounted() {
    this.paginate();
  }
};
</script>